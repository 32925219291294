import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[preventDefault]',
  standalone: true,
})
export class PreventDefaultDirective {
  constructor() {}

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
  }
}
